import React from 'react'
import './InstagramSection.css'
import { AiOutlineInstagram } from "react-icons/ai";
import { motion } from "framer-motion"


function InstagramSections() {
  return (
    <div className='InstagramSection'>
        <div className="instacontainer">
            <div className="instagram-feed">
                <motion.h1
                initial={{ opacity: 0,y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                >YÜKSEL HAIR CONCEPT İNSTAGRAM</motion.h1>
                <motion.a 
                initial={{ opacity: 0,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                id='insta-link' target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><AiOutlineInstagram id='social-logo2'/> @yukselhairconcept</motion.a>
                <div className="insta-grid">
                
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/Yuksel Hair Concept Instagram.jpg" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/Yuksel Hair Concept Facebook.jpg" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/Yuksel Hair Concept Youtube.jpg" alt="" /></motion.a>
                
                <motion.a 
                initial={{ opacity: 1,x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/ısparta sac bakım maskesi.jpg" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/ısparta low fade.jpg" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/ısparta profesyonel sac kesimi.jpg" alt="" /></motion.a>
                
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/ısparta modern sac kesimleri.jpg" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/ısparta en iyi erkek kuaforu.jpg" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img src="assets/ısparta mid fade.jpg" alt="" /></motion.a>
                </div>
            </div>
        </div>
    </div>
  )
}

export default InstagramSections

