import React from 'react'
import './pricelist.css'
import { motion } from "framer-motion"

function PriceList() {
  return (
    <>
        <div id='price' className="Pricelist">
            <div className="containerpr">
                <motion.img
                initial={{ opacity: 0,y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                id='pricel' src="/assets/Yüksel Hair Concept Berber.png" alt="" />
                <motion.div
                initial={{ opacity: 0,y: 10 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1.2 }}
                viewport={{ once: true }}
                className="white-stick"></motion.div>
                <div className="prices">
                    <motion.div
                    initial={{ opacity: 0,x: -100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1.7 }}
                    viewport={{ once: true }}
                    className="leftprice">
                        <ul>
                            <li><span>Saç Kesimi</span><span>200 TL</span></li>
                            <li><span>Sakal Kesimi</span><span>100 TL</span></li>
                            <li><span>Asker Tıraşı</span><span>150 TL</span></li>
                            <li><span>Sadece Ense tıraşı</span><span>50 TL</span></li>
                            <li><span>Yıkama - Fön</span><span>70 TL</span></li>
                            <li><span>Ağda (Yanak)</span><span>50 TL</span></li>
                            <li><span>Ağda (Komple Yüz)</span><span>100 TL</span></li>
                            <li><span>Cilt Bakım Buhar</span><span>400 TL</span></li>
                        </ul>
                    </motion.div>
                <motion.div 
                initial={{ opacity: 1,height: 0 }}
                whileInView={{ opacity: 1, height: 200 }}
                transition={{ duration: 1.5 }}
                viewport={{ once: true }}
                className="white-middle-stick"></motion.div>
                    <motion.div
                    initial={{ opacity: 0,x: 100 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1.7 }}
                    viewport={{ once: true }}
                    className="rightprice">
                        <ul>
                            <li><span>3'lü Set Maske</span><span>100 TL</span></li>
                            <li><span>Kil Maskesi</span><span>70 TL</span></li>
                            <li><span>Saç Bakım Maskesi</span><span>130 TL</span></li>
                            <li><span>Dexe (Beyaz Kapatıcı)</span><span>150 TL</span></li>
                            <li><span>Perma</span><span>800 TL</span></li>
                            <li><span>Kreatin Düzleştirme</span><span>400 TL</span></li>
                            <li><span>Protez Bakımı</span><span>550 TL</span></li>
                            <li><span>Protez Bakımı (Band Size Ait)</span><span>400 TL</span></li>
                        </ul>
                    </motion.div>
                </div>
            </div>
        </div>
    </>
    )
}

export default PriceList

