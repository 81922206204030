import React from 'react'
import './CardsSection.css'
import { Navigation, Pagination, Scrollbar, A11y, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { motion } from "framer-motion"



function CardsSection() {

    

  return (
    <div id="cards" className='CardsSection'>
        <div className="containerc">
            <motion.h1 
            initial={{ opacity: 0,y: 30 }}
                    viewport={{ once: true }}
                    whileInView={{ opacity: 1, y: 0  }}
            transition={{ duration: 0.3 }}
            id='CardsHeader'>HİZMETLERİMİZ</motion.h1>
            <div className="mobileslider">
                <Swiper
                slidesPerView={1}
                modules={[Navigation, Pagination]}
                navigation
                pagination
                loop
                centeredSlides={true}
                grabCursor={true}
                >
                <SwiperSlide data-swiper-slide-index='1' className='cardSwiper2'>
                <div className="Ccard">
                    <img src="assets/ısparta skin fade sac kesimi.jpg" alt="" />
                    <div className="CardText">
                        <h1>Skin Fade</h1>
                        <p>Skin Fade genel olarak enseden yukarı doğru uzayarak kesilmiş saç modeline verilen ve saç trendleri arasında kendini göstermeye başlayan bir stil</p>
                    </div>
                    <a href="/skin-fade-sac-modeli">DETAYLAR</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='2' className='cardSwiper2'>
                <div className="Ccard">
                    <img src="assets/ısparta anatomik kesim.jpg" alt="" />
                    <div className="CardText">
                        <h1>Anatomik Kesim</h1>
                        <p>Anatomik Kesim müşterinin kafa ve yüz hatlarına göre kesilen saç modelidir.</p>
                    </div>
                    <a href="tel:+90 531 287 20 81">RANDEVU AL</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='3' className='cardSwiper2'>
                <div className="Ccard">
                    <img src="assets/ısparta orgu sac.jpg" alt="" />
                    <div className="CardText">
                        <h1>Brezilya Örgü</h1>
                        <p></p>
                    </div>
                    <a href="tel:+90 531 287 20 81">RANDEVU AL</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='4' className='cardSwiper2'>
                <div className="Ccard">
                    <img src="assets/ısparta perma.jpg" alt="" />
                    <div className="CardText">
                        <h1>Perma</h1>
                        <p>Perma saç, stilinize kazandırabileceğiniz en hoş görünümlerden biri. Eğer eski saç modelinizden sıkıldıysanız ve daha hareketli bir şeyler arıyorsanız perma yaptırmayı düşünebilirsiniz.</p>
                    </div>
                    <a href="/perma-sac-modeli">DETAYLAR</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='5' className='cardSwiper2'>
                <div className="Ccard">
                    <img src="assets/ısparta protez sac.jpg" alt="" />
                    <div className="CardText">
                        <h1>Protez Saç</h1>
                        <p>Protez Saç, saç kaybı yaşayan kişinin sorun yaşadığı saç bölgesi için uygulanır. Aynı ölçülerde ve yüzde 100 insan saçından, kendi saç özelliklerine uygun saç protezi hazırlanır.</p>
                    </div>
                    <a href="/protez-sac-modeli">DETAYLAR</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='6' className='cardSwiper2'>
                <div className="Ccard">
                    <img src="assets/ısparta erkek sac boyama.jpg" alt="" />
                    <div className="CardText">
                        <h1>Renklendirme</h1>
                        <p>Saçınızda Grinin 51.ci Tonu.</p>
                    </div>
                    <a href="/renkli-sac-modeli">DETAYLAR</a>
                </div>
                </SwiperSlide>
                </Swiper>
            </div>
           <div className="CardSlider">
            <div className="Cardsss">
            <Swiper
            modules={[Navigation, Pagination, EffectCoverflow]}
            slidesPerView={'auto'}
            centeredSlides={true}
            grabCursor={true}
            loop
            navigation
            spaceBetween={150}
            effect={'coverflow'}
            coverflowEffect={{
                rotate: 10,
                stretch: 0,
                depth: 400,
                modifier: 1,
                slideShadows: false
            }}
            
            >
                <SwiperSlide data-swiper-slide-index='1' className='cardSwiper'>
                <div className="Ccard">
                    <img src="assets/ısparta skin fade sac kesimi.jpg" alt="" />
                    <div className="CardText">
                        <h1>Skin Fade</h1>
                        <p>Skin Fade genel olarak enseden yukarı doğru uzayarak kesilmiş saç modeline verilen ve saç trendleri arasında kendini göstermeye başlayan bir stildir.
                            Low Fade düşük solma, Mid Fade orta solma, High Fade yüksek solma.
                        </p>
                    </div>
                    <a href="/skin-fade-sac-modeli">DETAYLAR</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='2' className='cardSwiper'>
                <div className="Ccard">
                    <img src="assets/ısparta anatomik kesim.jpg" alt="" />
                    <div className="CardText">
                        <h1>Anatomik Kesim</h1>
                        <p>Anatomik Kesim müşterinin kafa ve yüz hatlarına göre kesilen saç modelidir.</p>
                    </div>
                    <a href="tel:+90 531 287 20 81">RANDEVU AL</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='3' className='cardSwiper'>
                <div className="Ccard">
                    <img src="assets/ısparta orgu sac.jpg" alt="" />
                    <div className="CardText">
                        <h1>Brezilya Örgü</h1>
                        <p></p>
                    </div>
                    <a href="tel:+90 531 287 20 81">RANDEVU AL</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='4' className='cardSwiper'>
                <div className="Ccard">
                    <img src="assets/ısparta perma.jpg" alt="" />
                    <div className="CardText">
                        <h1>Perma</h1>
                        <p> Perma saç, stilinize kazandırabileceğiniz en hoş görünümlerden biri. Eğer eski saç modelinizden sıkıldıysanız ve daha hareketli bir şeyler arıyorsanız perma yaptırmayı düşünebilirsiniz.</p>
                    </div>
                    <a href="/perma-sac-modeli">DETAYLAR</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='5' className='cardSwiper'>
                <div className="Ccard">
                    <img src="assets/ısparta protez sac.jpg" alt="" />
                    <div className="CardText">
                        <h1>Protez Saç</h1>
                        <p>Protez Saç, saç kaybı yaşayan kişinin sorun yaşadığı saç bölgesi için uygulanır. Aynı ölçülerde ve yüzde 100 insan saçından, kendi saç özelliklerine uygun saç protezi hazırlanır.</p>
                    </div>
                    <a href="protez-sac-modeli">DETAYLAR</a>
                </div>
                </SwiperSlide>
                <SwiperSlide data-swiper-slide-index='6' className='cardSwiper'>
                <div className="Ccard">
                    <img src="assets/ısparta erkek sac boyama.jpg" alt="" />
                    <div className="CardText">
                        <h1>Renklendirme</h1>
                        <p>Saçınızda grinin 51'inci tonu</p>
                    </div>
                    <a href="/renkli-sac-modeli">DETAYLAR</a>
                </div>
                </SwiperSlide>
                
            </Swiper>
            </div>
           </div>
        </div>
    </div>
)
}

export default CardsSection