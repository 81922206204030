import React from 'react'
import './ContactSection.css'
import { CgFacebook } from "react-icons/cg";
import { AiOutlineInstagram } from "react-icons/ai";
import { GrYoutube } from "react-icons/gr";
import { MdOutlineLocationOn } from "react-icons/md";
import { GoMail } from "react-icons/go";
import { motion } from "framer-motion"


function UsualSection() {
  return (
    <div id='contact' className='UsualSection'>
      
        <div className="containercc">
          <div className="stripe-wrapper">
            <div className="orange-stripe2">
               <motion.h1
               initial={{ opacity: 1,x: -150 }}
               whileInView={{ opacity: 1, x: 0 }}
               transition={{ duration: 1 }}
               viewport={{ once: true }}
               >BİZE ULAŞIN</motion.h1> 
            </div>
          </div>
          
           <div className="section-1">
                <div className="left-section2">
                <motion.h1
                initial={{ opacity: 0,y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                >İLETİŞİM</motion.h1>
                <motion.a 
                initial={{ opacity: 0,x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.2 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://goo.gl/maps/fP2f5ForjYoPEV3K8"><p id='adress'><MdOutlineLocationOn id='adre'/> Kutlubey, 1017. Sk. no:8/b, 32100 Merkez/Isparta</p></motion.a>
                <motion.a 
                initial={{ opacity: 0,x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.4 }}
                viewport={{ once: true }}
                href="tel:+90 531 287 20 81"><p id='telefon'>📞 0 531 287 20 81</p></motion.a>
                <motion.a 
                initial={{ opacity: 0,x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.6 }}
                viewport={{ once: true }}
                href="tel:+90 539 357 55 52"><p id='telefon2'>📞 0 539 357 55 52</p></motion.a>
                <motion.a 
                initial={{ opacity: 0,x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1.8 }}
                viewport={{ once: true }}
                href="mailto:mustafa_19971946@hotmail.com"><p id='mail'><GoMail id='mayil'/> mustafayuksel3207@gmail.com</p></motion.a>
                <motion.div 
                initial={{ opacity: 0,y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="social-media">
                  <a id='facee' target={'_blank'} href="https://m.facebook.com/people/Y%C3%9CKSEL-HAIR-CONCEPT/100058614799634/"><CgFacebook id='social-logo'/></a>
                  <a id='instta' target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><AiOutlineInstagram id='social-logo'/></a>
                  <a target={'_blank'} href="https://www.youtube.com/channel/UCU81FNjFTWCVBiEyGW1XzlQ"><GrYoutube id='social-logo'/></a>
                </motion.div>  
            </div>
            <div className="right-section"> 
              <div className="right-image">
              <motion.img
              initial={{ opacity: 0,y: -50 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 1 }}
              viewport={{ once: true }}
              id='right-image2' src="assets/ısparta berber.jpg" alt="" />
              </div>
            </div>
              </div>
              <div className="konum-wrapper">
                <a target={'_blank'} href="https://goo.gl/maps/97QPAuHFwsu6Pzxw7"><motion.img
                 initial={{ opacity: 0,y: -50 }}
                 whileInView={{ opacity: 1, y: 0 }}
                 transition={{ duration: 1 }}
                 viewport={{ once: true }}
                 id='konum' src="assets/ısparta yüksel hair concept konum.png" alt="" /></a>
                 
              </div>
             
        </div>
    </div>
  )
}

export default UsualSection