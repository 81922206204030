import React, { useRef, useState } from 'react'
import './perma.css'
import { AiFillStar } from "react-icons/ai";
import { MdCleanHands } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { BsLightningChargeFill } from "react-icons/bs";
import { motion } from "framer-motion"
import { AiOutlineInstagram } from "react-icons/ai";
import ContactSection from '../ContactSection';
import CardsSection from '../CardsSection';
import Navotti from './tempNav';
import { Helmet } from 'react-helmet';



function Perma() {

    const images = [
        {
            thumbnail: 'assets/skin2.png',
            video: 'assets/v2-9.mp4'
        },

        {
            thumbnail: 'assets/skin1.png',
            video: 'assets/v2-10.mp4'
        },
    ];

    const [img, setImg] = useState(images[0]);
    const clkHandler = (image, i) => {
        setImg(image);
        refs.current[i].classList.add('activ');
        for (let j = 0; j < images.length; j++) {
            if (i!==j) {
                refs.current[j].classList.remove('activ');
            }
        }
    };

    
    

    const refs = useRef([]);
    refs.current = [];
    const addRefs = (el) => {
        if(el && !refs.current.includes(el)){
            refs.current.push(el)
        }
    };

  return (
    <>
    <Helmet>
        <title>Skin Fade Saç Modeli 0 531 287 20 81, 0539 357 55 52. Yüksel Hair Concept, Isparta Erkek Kuaförü</title>
        <meta name='description' content='Yüksel Hair Concept Skin Fade Saç modeli, Isparta Skin Fade Saç kesimi, 0 531 287 20 81 - 0539 357 55 52, Isparta Erkek Kuaförü, Isparta Erkek Berberi, Skin Fade Fiyat, Skin Fade Saç Modelleri, Skin Fade Nasıl Yapılır, Erkek Saç Modelleri, Saç Tıraşı, En İyi Erkek Saç Modelleri, Profesyonel Saç Kesimi, Isparta Profesyonel Erkek Kuaförü.' />
    </Helmet>
    <Navotti/>
        <div className="Skin-Fade">
            <div className="containerskin">
            <section className="first-modal-section">
                <div className="left-image-slider">
                    <motion.div 
                    initial={{ opacity: 0,x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className="thumbnail">
                      {images.map((image, i) => (
                        <div className={i == 0 ? 'imgwrap activ' : 'imgwrap'}  key={i} onClick={() => clkHandler(image, i)} ref={addRefs}>
                            <img src={image.thumbnail} alt="" />
                        </div>
                    ))}  
                    </motion.div>
                    <div className="riggtimg">
                        <motion.video
                        initial={{ opacity: 0,y: -50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        autoPlay controls src={img.video}></motion.video>
                    </div>
                </div>
                <div className="right-modal-info">
                <motion.h1 
                initial={{ opacity: 0,x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }} className="modal-name">SKİN FADE Saç Modeli</motion.h1>
                <motion.div
                initial={{ opacity: 0,x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="stars">
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                </motion.div>
                <motion.h2 
                initial={{ opacity: 0,x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className='modal-price'>110 TL</motion.h2>
                <motion.a  
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                href="tel:+90 531 287 20 81" className="modal-button">Randevu Al</motion.a>
                <motion.div
                initial={{ opacity: 0,y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="ticks">
                    <p><MdCleanHands id='clean'/><span>Hijyenik</span></p>
                    <p><BsLightningChargeFill id='hizli'/><span>Hizli</span></p>
                    <p id='nib'><TiTick id='ozel'/> <span>Size göre</span></p>                    
                </motion.div>
                </div>
            </section>
            <section className="modal-info">
                <div className="modal-info-text">
                    <motion.h1
                    initial={{ opacity: 0,x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    >Skin Fade Nedir?</motion.h1>
                <motion.p
                initial={{ opacity: 0,y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                >Fade, genel olarak enseden yukarı doğru uzayarak kesilmiş saç modeline verilen ve saç trendleri arasında kendini göstermeye başlayan bir stil. Özellikle uzun saçların moda olmasından sonra farklılıklara giden erkeklerin tercih ettiği bu saç stili aslında daha önce birçok ünlü isim kullansa da şimdilerde yeni ismiyle trendler arasına girdi.</motion.p>
                </div>
                
                <div className="left-imae2">
                <motion.img
                initial={{ opacity: 0,y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                src="assets/skin1.png" alt="" />
                </div>
            </section>
            <div className="modal-samples">
            <div className="instagram-feed">
                <motion.h1
                initial={{ opacity: 0,y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                >Bu Modeli Kullananlar</motion.h1>
                <motion.a 
                initial={{ opacity: 0,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                id='insta-link2' target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><AiOutlineInstagram id='social-logo2'/> @yukselhairconcept</motion.a>
                <div className="insta-grid2">
                
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img id='orabna' src="assets/skin1.png" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img id='orabna' src="assets/skin2.png" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img id='orabna' src="assets/skin3.png" alt="" /></motion.a>
                
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img id='orabna' src="assets/skin4.png" alt="" /></motion.a>

<motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img id='orabna' src="assets/skin5.png" alt="" /></motion.a>


                <motion.a 
                initial={{ opacity: 1,x: -100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} id='nig' href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img id='orabna' src="assets/skin6.png" alt="" /></motion.a>
                </div>
            </div>
            </div>           
            </div>
            <CardsSection/> 
            <ContactSection/>
        </div>
    </>
    )
}

export default Perma

