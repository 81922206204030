import React from 'react'
import HeadSection from '../../components/HeadSection';
import UsualSection from '../../components/UsualSection';
import CardsSection from '../../components/CardsSection';
import QuoteSection from '../QuoteSection';
import ContactSection from '../ContactSection';
import InstagramSections from '../InstagramSections';
import PriceList from '../PriceList';
import Navbar from '../Navbar';

function Home() {
  return (
    <>
    <Navbar/>
    <HeadSection/>
    <CardsSection/>
    <QuoteSection/>
    <InstagramSections/>
    <PriceList/>
    <ContactSection/>
    </> 
    )
}

export default Home