import React from 'react'
import './QuoteSection.css'
import { motion } from "framer-motion"


function QuoteSection() {
  return (
    <div className='quote-section'>
      <div className="containerq">
        <div className="main-quote">
            <motion.h1
            initial={{ opacity: 0,x: 100 }}
            whileInView={{ opacity: 1, x: 0 }}
            transition={{ duration: 1 }}
                    viewport={{ once: true }}
            >"Günlük yaşamınızda , özel günlerinizde, yüz hattınıza göre ve ten renginize uygun saç ve sakalınızı şekillendirerek <span>tarzınızı yeniliyoruz</span>."</motion.h1>
            <motion.h3
            initial={{ opacity: 0,x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 1 }}
            >-Mustafa Yüksel</motion.h3>
            <motion.h3
            initial={{ opacity: 0,x: -100 }}
            whileInView={{ opacity: 1, x: 0 }}
                    viewport={{ once: true }}
                    transition={{ duration: 1 }}
            >-Ahmet Yüksel</motion.h3>
        </div>
      </div>
    </div>
  )
}

export default QuoteSection