import React from 'react'
import './Footer.css'
import { Link } from "react-scroll";


function Footer() {
  return (
    <div className='footer'>
        <div className="footer-text-container">
            <div className="footer-left-text">
            <img src="/assets/Yüksel Hair Concept Berber.png" alt="" />
            <p>Kutlubey, 1017. Sk. no:8/b, 32100 Merkez/Isparta</p>
            <p>0 531 287 20 81</p>
            <p>0 539 357 55 52</p>
            </div>
            <div className="footer-right-text">
                <div className="text-one">
                    <h2>NAVİGASYON</h2>
                    <Link to="homee" smooth={true} offset={-100} duration={1000}><p>ANASAYFA</p></Link>
                    <Link to="cards" smooth={true} offset={0} duration={700}><p>HİZMETLERİMİZ</p></Link>
                    <Link to="us" smooth={true} offset={0} duration={500}><p>HAKKIMIZDA</p></Link>
                    <Link to="contact" smooth={true} offset={50} duration={300}><p>İLETİŞİM</p></Link>
                </div>
                <div className="text-two">
                    <h2>SOSYAL MEDYA</h2>
                    <a href="https://www.instagram.com/yukselhairconcept/?hl=tr"><p>INSTAGRAM</p></a>
                    <a href="https://www.youtube.com/channel/UCU81FNjFTWCVBiEyGW1XzlQ"><p>YOUTUBE</p></a>
                    <a href="https://www.facebook.com/people/Y%C3%9CKSEL-HAIR-CONCEPT/100058614799634/?paipv=0&eav=AfbVzf0k8jfdG973z4aiQIkA27wDopMUzeHIRyd7GznSSSRrE8ymZMfEVIgtyzvEVOI&_rdr"><p>FACEBOOK</p></a>
                </div>
                <div className="text-three">
                    <h2>ÇALIŞMA SAATLERİMİZ</h2>
                    <p>Hafta İçi: 8:30 - 22:00,</p>
                    <p>Cumartesi: 8:30 - 22:00</p>
                    <p>Pazar: 9:00 - 20:00</p>  
                </div>
            </div>
           
        </div>
        <div className="footer-copyright">
            <p>Powered by OE-Tasarım</p>
            <p>© 2023 YÜKSEL HAIR CONCEPT</p>
        </div>
    </div>
  )
}

export default Footer