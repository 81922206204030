
import React, { useState, useEffect } from 'react';
import { Link } from "react-scroll";
import './Navbar.css';
import { FaBars, FaTimes, FaShoppingCart } from 'react-icons/fa';
import { RiFridgeLine } from 'react-icons/ri';
import { GiAutoRepair } from 'react-icons/gi';
import { AiTwotonePhone } from 'react-icons/ai';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 160) {
      setButton(false);
    } else {
      setButton(true);
    }
  };
  

  return (
    <>    
              <nav className='navbar'>
          <div className='navbar-container container'>
            
            <a href='/' className='navbar-logo' onClick={closeMobileMenu}>
            <img id='logo' src="/assets/Yuksel Hair Concept Berber.png" alt="" />
            </a>
            <div className='menu-icon' onClick={handleClick}>
              {click ? <FaTimes /> : <FaBars />}
            </div>
           
          
           
            <ul className={click ? 'nav-menu active' : 'nav-menu'}>

            <li className='nav-item nav-1'>
                 <Link to="homee" smooth={true} offset={-100} duration={500} className='nav-links' onClick={closeMobileMenu}>
                   ANASAYFA
                 </Link>
               </li>

            <li id="kellog" className='nav-item'>
                 <Link to="cards" smooth={true} offset={50} duration={600} id='bruhh' onClick={closeMobileMenu} className='nav-links nav-1' >
                  HİZMETLERİMİZ
                   </Link>
                 </li>
                 <li className='nav-item'>
                 <Link to='price' smooth={true} offset={-150} duration={800} className='nav-links nav-1' onClick={closeMobileMenu}>
                  FİYAT LİSTESİ
                 </Link>
               </li>
               <li className='nav-item'>
                 <Link to='us' smooth={true} offset={0} duration={800} className='nav-links nav-1' onClick={closeMobileMenu}>
                  HAKKIMIZDA
                 </Link>
               </li>
               
               <li className='nav-item'>
                 <Link to='contact' smooth={true} offset={50} duration={1000} className='nav-links nav-1' onClick={closeMobileMenu}>
                    İLETİŞİM
                 </Link>
               </li> 
               </ul>
                <a id='obamna' href="tel:+90 531 287 20 81">RANDEVU AL</a>
          </div>
        </nav>
    </>
  );
}

export default Navbar;


