import React, { useRef, useState } from 'react'
import './protezsac.css'
import { AiFillStar } from "react-icons/ai";
import { MdCleanHands } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { BsLightningChargeFill } from "react-icons/bs";
import { motion } from "framer-motion"
import { AiOutlineInstagram } from "react-icons/ai";
import ContactSection from '../ContactSection';
import CardsSection from '../CardsSection';
import Navotti from './tempNav';
import { Helmet } from 'react-helmet';




function SkinFade() {

    const images = [
        {
            thumbnail: 'assets/renk1.png',
            video: 'assets/v2-6.mp4'
        },

        {
            thumbnail: 'assets/renkli2.png',
            video: 'assets/v2-7.mp4'
        },

        
    ];

    const [img, setImg] = useState(images[0]);
    const clkHandler = (image, i) => {
        setImg(image);
        refs.current[i].classList.add('activ');
        for (let j = 0; j < images.length; j++) {
            if (i!==j) {
                refs.current[j].classList.remove('activ');
            }
        }
    };

    
    

    const refs = useRef([]);
    refs.current = [];
    const addRefs = (el) => {
        if(el && !refs.current.includes(el)){
            refs.current.push(el)
        }
    };

  return (
    <>
    <Helmet>
        <title>Renkli Saç Modeli 0 531 287 20 81, 0539 357 55 52. Yüksel Hair Concept, Isparta Erkek Kuaförü</title>
        <meta name='description' content='Yüksel Hair Concept Renkli Saç modeli, Isparta Renkli Saç kesimi, Isparta Erkek Saç Boyama, 0 531 287 20 81 - 0539 357 55 52, Isparta Erkek Kuaförü, Isparta Erkek Berberi, Renkli Saç Fiyat, Renkli Saç Modelleri, Renkli Saç Nasıl Yapılır, Saç Nasıl Boyanır, Erkek Saç Modelleri, Saç Tıraşı, En İyi Erkek Saç Modelleri, Profesyonel Saç Kesimi, Isparta Profesyonel Erkek Kuaförü.' />
    </Helmet>
    <Navotti/>
        <div className="Skin-Fade">
            <div className="containerskin">
            <section className="first-modal-section">
                <div className="left-image-slider">
                    <motion.div 
                    initial={{ opacity: 0,x: -50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    className="thumbnail">
                      {images.map((image, i) => (
                        <div className={i == 0 ? 'imgwrap activ' : 'imgwrap'}  key={i} onClick={() => clkHandler(image, i)} ref={addRefs}>
                            <img src={image.thumbnail} alt="" />
                        </div>
                    ))}  
                    </motion.div>
                    <div className="riggtimg">
                        <motion.video
                        initial={{ opacity: 0,y: -50 }}
                        whileInView={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1 }}
                        viewport={{ once: true }}
                        autoPlay controls src={img.video}></motion.video>
                    </div>
                </div>
                <div className="right-modal-info">
                <motion.h1 
                initial={{ opacity: 0,x: 50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }} className="modal-name">SAÇ RENKLENDİRME</motion.h1>
                <motion.div
                initial={{ opacity: 0,x: -50 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="stars">
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                <AiFillStar/>
                </motion.div>
                <motion.a  
                initial={{ opacity: 0 }}
                whileInView={{ opacity: 1 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                href="tel:+90 531 287 20 81" className="modal-button">Randevu Al</motion.a>
                <motion.div
                initial={{ opacity: 0,y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                className="ticks">
                    <p><MdCleanHands id='clean'/><span>Hijyenik</span></p>
                    <p><BsLightningChargeFill id='hizli'/><span>Hizli</span></p>                    
                </motion.div>
                </div>
            </section>
            <section className="modal-info">
                <div className="modal-info-text">
                    <motion.h1
                    initial={{ opacity: 0,x: 50 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ duration: 1 }}
                    viewport={{ once: true }}
                    >Saç Renklendirme Nedir?</motion.h1>
                <motion.p
                initial={{ opacity: 0,y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                >Erkek saç boyası aslında standart bir saç boyama işlemidir. Yani kadınlarda uygulanana prosedürler aynı şekilde uygulanır ve kişilerin saçlarında renklendirme işlemi sağlanır. Kendi doğal saçı işle aynı renk boyatmak isteyen erkeklere aynı renk uygulaması yapılır. İmaj veya stil için saçını boyatmak isteyen erkeklere ise belirli bölgelere paket uygulamaları yapılır.</motion.p>
                </div>
                
                <div className="left-imae">
                <motion.img
                initial={{ opacity: 0,y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                src="assets/renk1.png" alt="" />
                </div>
            </section>
            <div className="modal-samples">
            <div className="instagram-feed">
                <motion.h1
                initial={{ opacity: 0,y: -50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                >Bu Modeli Kullananlar</motion.h1>
                <motion.a 
                initial={{ opacity: 0,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                id='insta-link2' target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><AiOutlineInstagram id='social-logo2'/> @yukselhairconcept</motion.a>
                <div className="insta-grid2">
                
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img id='orabna' src="assets/renk1.png" alt="" /></motion.a>
                <motion.a 
                initial={{ opacity: 1,x: 100 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                target={'_blank'} href="https://www.instagram.com/yukselhairconcept/?hl=tr"><img id='orabna' src="assets/renkli2.png" alt="" /></motion.a>
                 </div>
            </div>
            </div>           
            </div>
            <CardsSection/> 
            <ContactSection/>
        </div>
    </>
    )
}

export default SkinFade

