import React from 'react'
import './HeadSection.css'
import { motion } from "framer-motion"


function HeadSection() {
  return (
    <div id='homee' className='HeadSection'>
       
        <div className="containerh">
           <div className="background-images">
           <img src="assets/ısparta sac sekillendirme.jpg" id='a' className='back-image' alt="" />
        <img src="assets/ısparta erkek kuafor salonu.jpg" id='b' className='back-image' alt="" />
        <img src="assets/ısparta sakal sekillendirme.jpg" id='c' className='back-image' alt="" />
        <img src="assets/ısparta erkek sac renklendirme.jpg" id='d' className='back-image' alt="" />
        <img src="assets/Isparta Erkek Kuaforu.jpg" id='e' className='back-image' alt="" />
        <img src="assets/ısparta kil maskesi.jpg" id='j' className='back-image' alt="" />
           </div>
            <div className="mainText">
               
                <motion.p 
                 initial={{ y: -100 }}
                 animate={{ y: 0 }}
                    viewport={{ once: true }}
                 transition={{ duration: 1 }}
                id='iis'>ISPARTA ERKEK KUAFÖRÜ</motion.p>
                <div className="middleText">
                   <h1><motion.span
                   initial={{ opacity: 0,x: -100 }}
                    viewport={{ once: true }}
                    animate={{ opacity: 1, x: 0 }}
                   transition={{ duration: 1.6 }} 
                   id='hm'>YÜKSEL</motion.span><motion.span 
                   initial={{ opacity: 0,x: 100 }}
                    viewport={{ once: true }}
                    animate={{ opacity: 1, x: 0 }}
                   transition={{ duration: 1.8 }} 
                   >HAIR CONCEPT</motion.span></h1>
                   <motion.p
                  initial={{ opacity: 0,y: 20 }}
                    viewport={{ once: true }}
                    animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 2 }} 
                   >Isparta'nın en iyi Erkek Kuaförü. Tarzınızı yenileyin ve yüzünüze renk katın. Saç modellerimize göz atın.</motion.p> 
                </div>
                <motion.a 
                initial={{ opacity: 0,x: 20 }}
                    viewport={{ once: true }}
                    animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }} 
                href="tel:+90 531 287 20 81">RANDEVU AL</motion.a>
            </div>
        <div className="orange-stripe">
           <marquee behavior="scroll" scrollamount="12" direction="right"><h1>sakal şekİllendİrme-brezİlya örgü-renklendİrme-protez saç-anatomİk Kesİm-skin fade-perma</h1></marquee> 
        </div>
        </div>
         
    </div>
  )
}

export default HeadSection