import React from 'react';
import './App.css';
import { BrowserRouter as Router, Switch, Route, Routes } from 'react-router-dom';

import Footer from './components/Footer';
import Home from './components/Pages/Home';
import ProtezSac from './components/Pages/ProtezSac';
import Perma from './components/Pages/Perma';
import Renklisac from './components/Pages/renklisac';
import Skin from './components/Pages/skin';

function App() {
  return (
    <Router>
      <Routes>
        <Route path='/' exact element={<Home/>}/>
        <Route path='/protez-sac-modeli' exact element={<ProtezSac/>}/>
        <Route path='/perma-sac-modeli' exact element={<Perma/>}/>
        <Route path='/renkli-sac-modeli' exact element={<Renklisac/>}/>
        <Route path='/skin-fade-sac-modeli' exact element={<Skin/>}/>
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
